import { Modal } from 'react-bootstrap';
import styled from "styled-components";
const BoldFont = 'Rubik-Bold';
import { ImageUrl } from '../../../utils/constants';
const backgroundUrl = `url(${ImageUrl}/images/health-data/health-insights.svg)`;

const StyledModal = styled(Modal)`
	.modal-dialog{
		display: flex;
    justify-content: center;
    transform: ${({ transform }) => transform && 'translate(0, 80px) translate(0, -90%) !important'};
		.modal-content{
      width: ${({ newWidth }) => newWidth ? newWidth : '100%'};
	  height: auto;
	  box-shadow: none;
	  position: absolute;
	  border-radius: 10px;
	  padding-bottom: ${({ paddingBottom }) => paddingBottom ? paddingBottom : '0px'};
			.modal-header {
				border-bottom: ${({ noBorder }) => noBorder ? noBorder : '1px solid rgba(0, 92, 135, 0.30)'};
                background: linear-gradient(0deg, #8AD3F7 0%, #52A8ED 100%);
				border-radius: 6px 6px 0 0;
				height: ${({ height }) => height ? height : ''};
				>button{
				 display: ${({ display }) => display ? display : ''};
				}
				.modal-container {
					width: 100%;
					display: block;
					> div: nth-child(1) {
					 width: 100%;
					 height: 0px;
					 display: flex;
					 justify-content: flex-end;
					 >img{
					   width: 24px;
					   height: 24px;
					   cursor: pointer;
					   margin: 15px 15px 0 0;
					 }
					}
					> div: nth-child(2) {
					 width: 100%;
					 display:flex;
					 justify-content: center;
					 font-family: Rubik-Medium;
					 font-size: 24px;
					 line-height: 24px;
					 color:#0D4270;
					 text-decoration: underline;
					 margin-top: 35px;
					}
				   }
				> div {
					display: flex;
					justify-content: center;
					> p{
						font-size: 20px;
						font-family: ${BoldFont};
						margin-top: 10px 0 0 0;
					}
				}
			}
			.modal-header .close {
				margin-top: -2px;
				outline: none;
				display: none;
			.cross_img {
				float: right;
				> img {
					width: 24px;
					height: 24px;
					cursor: pointer;
				}
			}
		}
	}
  @media (max-width: 500px) and (min-width: 320px) {
    .modal-dialog {
			width: 100%;

			.modal-content{
				width: 90%;
				margin-left: -6%;
			}
    }
  }
`;

const StyledTitle = styled(Modal.Title)`
font-family: 'Rubik-Medium';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
text-align: left;
color: ${({ color }) => color ? '#0D4270' : '#ffff'};
>svg{
	display:flex;
	margin:auto 0px auto auto;
}
`;

const StyledBody = styled(Modal.Body)`
 width: 100%;
`;
const CompayContainer = styled.div`
   width: 50%;
   float: left;
   display: flex;
`;
const CompanyButton = styled.button`
    margin: 10px;
    width: 230px;
	padding: 5px;
    height: 80px;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    border-radius: 6px;
     display: flex;
	 background-color: #ffff;
.image_cls
{
	padding: 4px;	
	> img {
		width: 60px;
		height: 60px;
	 } 
}
 .name {
    height: 40px;
    font-family: Rubik;
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 20px;
   display: flex;
 text-align: left;
 color: #0D4270;
 padding: 0px;
 margin: auto;
 align-items: center;
 margin-left: 10px;
 }
`
const ConfirmButton = styled.div`
width: 100%;
display: flex;
justify-content: center;
.confirm_cls {
width: 300px;
height: 40px;
background-color: rgba(105, 194, 255, 1);
border-radius: 3px;
margin-top: 25px;
margin-bottom: 25px;
display: flex;
justify-content: center;
align-items: center;
>span {
Font family: Rubik
Font style: Medium
Font size: 16px
Line height: 16.5px;
Align: Center
Vertical align: Top
color: #fff;
}
}
`;

const StyledModalV2 = styled(Modal)`
	.modal-dialog{
		display: flex;
    justify-content: center;
    transform: ${({ transform }) => transform && 'translate(0, 80px) translate(0, -90%) !important'};
		.modal-content{
      width: ${({ newWidth }) => newWidth ? newWidth : '100%'};
	  height: 600px;
	  box-shadow: none;
	  position: absolute;
	  border-radius: 10px;
	  padding-bottom: ${({ paddingBottom }) => paddingBottom ? paddingBottom : '0px'};
			.modal-header {
				border-bottom: ${({ noBorder }) => noBorder ? noBorder : '1px solid #e5e5e5'};
                background: linear-gradient(0deg, #8AD3F7 0%, #52A8ED 100%);
				border-radius: 6px 6px 0 0;
				padding: 0px;
				height: 50px;
				.modal-container {
					width: 100%;
					display: block;
					height: 100%;
					> div: nth-child(1) {
					 width: 100%;
					 height: 0px;
					 display: flex;
					 justify-content: flex-end;
					 >img{
					   width: 24px;
					   height: 24px;
					   cursor: pointer;
					   margin: 13px 13px 0 0;
					 }
					}
					> div: nth-child(2) {
					 width: 100%;
					 display:flex;
					 justify-content: center;
					 font-family: Rubik-Medium;
					 font-size: 18px;
					 line-height: 24px;
					 color:#fff;
					 height: 100%;
					 display: flex;
					 justify-content: flex-start;
					 align-items: center;
					 padding-left: 15px;
					}
				   }
				> div {
					display: flex;
					justify-content: center;

					> p{
						font-size: 20px;
						font-family: ${BoldFont};
						margin-top: 10px 0 0 0;
					}
				}
			}
			.modal-header .close {
				margin-top: -2px;
				outline: none;
				display: none;
			.cross_img {
				float: right;
				> img {
					width: 20px;
					height: 20px;
					cursor: pointer;
				}
			}
		}
	}
  @media (max-width: 500px) and (min-width: 320px) {
    .modal-dialog {
			width: 100%;

			.modal-content{
				width: 90%;
				margin-left: -6%;
			}
    }
  }
`;

const CompayContainerV2 = styled.div`
   width: 100%;
   float: left;
`;

const StyledBodyV2 = styled(Modal.Body)`
 width: 100%;
 height: 550px;
 overflow-y: auto;
 background: white;
 border-radius: 0 0 6px 6px;
 padding: 10px 31px 0px 25px;
 ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    @media (max-width: 500px) {
      width: 5px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background: #c6c2c2;
    cursor: pointer;
    border-radius: 2.5px;
  }

  ::-webkit-scrollbar-track {
    background: #f2f0f0;
    border-radius: 2.5px;
  }
`;

const ShowPopupCard = styled.div`
 width: 100%;
 padding: 0px 0px 0px 5px;
 display: flex;
 border-top: ${({ border }) => border ? '': '1px solid rgba(156, 156, 156, 0.4)'};
.image{
	width: 50px;
	height: 50px;
	margin: 20px 15px 20px 5px;
	>img{
		width: 100%;
		height: 100%;
	}
}
.popup-name{
  display: block;
  margin: auto 0px;
  .name{
	font-family: Rubik;
	font-size: 16px;
	line-height: 20px;
	color: #0D4270;
	margin-bottom: 5px;
  }
  .point{
	font-family: Rubik;
	font size: 14px;
	line-height: 17px;
	color: ${({ color }) => color ? color : '#0D4270'};
  }
}
.percentage-main{
	display: flex;
	width: auto;
	margin: auto 0px auto auto;
	.percentage{
	width:72px;
	height: 38px;
	background: ${({ background }) => background ? background : '#fff'};
	display: flex;
	border-radius: 6px;
	display: flex;
    justify-content: center;
    align-items: center;

	font-family: Rubik-Medium;
	font-size: 16px;
	line-height: 19px;
	color:#0D4270;
}
}

`;

const InitiativesWrapper = styled.div`
width:100%;
display:flex;
flex-wrap:wrap;
`;

const DetailInitiativeWrapper = styled.div`
width:100%;
display:flex;
flex-wrap:wrap;
.row-one{
	display:flex;
	width:100%;
	justify-content:space-between;
	align-items:center;
	.image{
      width:60px;
	  height:60px;
	  background: ${({ background }) => background ? background : '#002f47'};
	  border-radius:6px;
	  display:flex;
	  >img{
		width:36px;
		height:36px;
		border-radius:6px;
		margin:auto;
	  }
	}
	.details{
		display:flex;
		flex-direction:column;
		align-items:start;
		justify-content:center;
		margin-left:10px;

		.name{
			font-family:Rubik-Medium;
			font-size:16px; // 18px;
			color:#005C87;
			line-height:24px;
		}
		.points{
			font-family:Rubik-Regular;
			font-size:16px; // 18px;
			color:rgba(0, 92, 135, 0.6);
			line-height:24px;

		}
	}
}
  .row-two{
	margin-top:24px;
	display:flex;
	justify-content:space-between;
	width:100%;

	.user-count{
		font-weight:500;
		font-size:14px;
		line-height:20px;
		font-family:Rubik-Medium;
	}

	.text{
		font-size:14px;
		line-height:20px;
		font-family:Rubik-Regular;
		color:rgba(0, 92, 135, 1);
	}

	.percent{
		font-weight:500;
		font-size:40px;
		line-height:20px;
		font-family:Rubik-Medium;
		${'' /* color:rgba(0, 92, 135, 1); */}
	}
  }
  .row-three{
	margin-top:20px;
	display:flex;
	width:100%;

	.taken{
		font-size:14px;
		line-height:20px;
		font-family:Rubik-Regular;
		color:rgba(0, 92, 135, 1);
	}
  }
}

.row-four{
	width:100%;
	.other-detail{
	margin-top:10px;
    display:flex;
	justify-content:space-between;
	width:100%;

	.title{
		font-size:16px;
		line-height:20px;
		font-family:Rubik-Regular;
		color: rgba(0, 92, 135, 0.6); // rgba(0, 92, 135, 1);

	}
	.info{
		font-size:16px;
		line-height:20px;
		font-family:Rubik-Medium;
		color:rgba(0, 92, 135, 1);

	}
	}
}

.detail-btn{
	display:flex;
	justify-content:center;
	width:100%;
	margin-top:30px;
	button{
		width:311px;
		height:50px;
		background:rgba(0, 92, 135, 1);
		border:none;
		color:white;
		border-radius:3px;
		font-family:Rubik-Medium;
		font-size:18px;
		line-height:24px;
	}
}
`;

const LaunchButton = styled.button`
	width:100px;
	height: 40px;
	border: ${({bgColor}) => bgColor ? `1px solid ${bgColor}` : '1px solid 005c87'}; // #005c87;
	border-radius: 6px;
	background: white;
	color: ${({bgColor}) => bgColor ? bgColor : '#005c87'}; // #005c87;
	// background: rgb(0, 122, 177);
	font-size: 18px;
	font-family: rubik-medium;
	&:hover{
		background: ${({bgColor}) => bgColor ? bgColor : '#005c87'}; // #005c87;
		color:white;
	}
`

const HoverBackground = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	z-index: 2;
	left: 0px;
	.view-button {
		font-family: Rubik-Medium;
		font-size: 18px;
		line-height: 24px;
		min-width: 160px;
		width: 140px;
		height: 48px;
		background: #007AB1;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: 4px;
		color: white;
		text-align: center;
		padding: 12px 20px 12px 20px;
	}
`

const HealthInsightsCard = styled.div`
margin-top: 20px;
width: 100%;
background: ${backgroundUrl};
    background-repeat: no-repeat;
    background-size: 100% ;
    position: relative;
	height: 260px;
	.text-container{
		display: flex;
		justify-content: center;
		flex-direction: column;
		height:100%;
		margin-left:20px;
	}

	.title-container{
		width:50%;
	}

	.title{
		color: #005C87;
        font-family: Rubik-Medium;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 48px;
	}

	.btn-container{
		margin-top: 20px;

	}

	.start-btn{
		display: flex;
width: 352px;
height: 48px;
padding: 4px;
justify-content: center;
align-items: center;
gap: 4px;
flex-shrink: 0;
		border-radius: 4px;
background: #007AB1;
color:  #FFF;
text-align: center;
font-family: Rubik-Medium;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; 
cursor: pointer;
	}

`



export { HoverBackground, StyledModal, StyledTitle, StyledBody, CompayContainer, CompanyButton, ConfirmButton, StyledModalV2, CompayContainerV2, StyledBodyV2, ShowPopupCard, InitiativesWrapper,LaunchButton, DetailInitiativeWrapper, HealthInsightsCard };